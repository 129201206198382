@use "/src/shared/styles/mixins/flexbox.scss";

.ActionButton {
  @include flexbox.inline-flexbox();
  flex-shrink: 0;

  &.size-xs {
    padding: 0;
  }

  &.size-s {
    padding: 4px;
  }

  &.size-m {
    padding: 10px;
  }

  &.size-l {
    padding: 12px;
  }

  &.rounded {
    border-radius: 50%;
  }

  .icon-wrapper {
    @include flexbox.inline-flexbox();
    position: relative;

    width: 100%;
    height: 100%;

    &:where(.isLoading) {
      &::after {
        content: "";
        position: absolute;
        inset: -10px;
        background-color: white;
      }
    }
  }
}
