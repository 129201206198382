#RegisterBoardLayout {
  margin-top: var(--size-gnb);
  padding-bottom: 100px;

  .hidden {
    display: none;
  }
  padding: var(--layout-padding);
  height: 100;
  background-color: white;
  #CheckBox,
  #Select {
    margin-bottom: 10px;
    + p {
      padding: 0 0 8px 0;
    }
    .disabled {
      color: var(--inline-color-gray10);
    }
  }
  button {
    width: 100%;
    &.ContainedBtn {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
  p {
    padding: 8px 0px;
  }

  .wrap + .wrap {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .prevent_auto {
    margin-top: 10px;
    margin-bottom: var(--layout-margin);
    img {
      width: 200px;
      height: 100px;
    }
    .wrap {
      display: flex;
      align-items: center;
      button {
        width: 20%;
        height: 30px;
        line-height: 5px;
        margin-left: 20px;
        font-size: 14px;
      }
    }
  }

  .image_section {
    display: flex;
    overflow-x: scroll;
    margin-bottom: 10px;

    img {
      width: 120px;
      height: 120px;
      border-radius: 3px;
      margin-right: 10px;
    }
  }
  .error {
    color: var(--color-brand-toneup-red);
  }

  .ContainedBtn {
    font-size: 16px;
  }
}
