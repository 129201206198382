@use "/src/shared/styles/mixins/flexbox.scss";

$gap: 12px;
$card-border: 1px solid var(--color-gray15);

$thumbnail-size: 86px;

.Card {
  &:where(.disabled) {
    filter: grayscale(70%);
    opacity: 0.6;
  }

  .metadata {
    @include flexbox.flexbox(between);

    padding: 0 4px;
    margin-bottom: 8px;

    color: var(--color-text-gray);
  }

  .inner {
    width: 100%;

    border: $card-border;
    border-radius: 5px;

    background-color: var(--color-white);
  }

  &Header {
    @include flexbox.flexbox(between);
    padding: $gap;

    &.hasBorder {
      border-bottom: $card-border;
    }
  }

  &Body {
    padding: 0 $gap 4px;

    &:not(:has(.CardSection)) {
      padding: $gap;
    }
  }

  &Section {
    padding: $gap 0 calc($gap - 3px);

    &:not(:last-of-type) {
      border-bottom: $card-border;
    }
  }

  &Footer {
    @include flexbox.flexbox(end);
    gap: 8px;

    padding: 8px $gap;
    border-top: $card-border;
  }
}

.Title {
  @include flexbox.flexbox();
  gap: 2px;

  .title {
    font-family: NotoSans_500;
    font-size: 14px;
    line-height: 22.5px;
    color: var(--inline-color-black);
  }

  hr {
    padding: 0px;
    border: none;
    background-color: var(--color-text-gray-light);
    margin: 0px 4px;
    width: 1px;
    height: 12px;
  }

  .subtitle {
    color: var(--color-text-gray);
    font-size: 14px;
  }
}
