.ContainedBtn {
  border: none;
  border-radius: 6px;
  padding: 13px;
  white-space: nowrap;
  color: var(--color-white);
  height: fit-content;
  font-size: 15px;
  &:disabled {
    background: var(--color-gray13);
    color: var(--color-gray167);
  }
}
.ContainedBtn_default {
  background: var(--color-brand-toneup-red);
  &:active {
    background: #c2186b;
  }
}

.ContainedBtn_black {
  background: var(--color-gray4);
  &:active {
    background: #333539;
  }
}

.ContainedBtn_disabled {
  background: var(--color-gray13);
  color: var(--color-gray167);
}

.ContainedBtn_blue {
  background-color: #10188d;
}
