$badgeSize: 8px;

.PushLinkIcon {
  position: relative;

  .alertBadge {
    position: absolute;
    top: 7px;
    right: 6px;

    width: $badgeSize;
    height: $badgeSize;

    border-radius: 16px;

    background-color: var(--color-red);
  }
}
