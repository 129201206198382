@use "/src/shared/styles/mixins/position.scss";

#BottomNavigation {
  @include position.fixed();
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-bottom-navigation);

  display: flex;
  justify-content: space-around;
  align-items: center;

  padding: 4px 0;
  padding-bottom: calc(env(safe-area-inset-bottom) + 8px);

  background-color: var(--color-white);
  transition: 0.4s ease;

  &.hide {
    transform: translateY(80px);
  }

  .navigation_button {
    all: unset;
    text-align: center;
    position: relative;
    display: block;
    width: 56px;
    height: 56px;
    p {
      display: inline-block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      text-align: center;
      font-size: 10px;
      line-height: 14px;
      color: var(--inline-color-gray10);
    }
  }
  &::before {
    position: absolute;
    top: -2px;
    right: 0;
    left: 0;
    background: linear-gradient(
      to top,
      var(--color-white),
      var(--color-gray15)
    );
    height: 2px;
    content: "";
  }
}
