.OutlinedBtn {
  border-radius: 6px;
  padding: 13px;
  white-space: nowrap;
  background: white;
  color: var(--inline-color-gray10);
  &:active {
    background: #f0f0f0;
  }
  &:disabled {
    border: 1px solid var(--color-gray13);
    color: var(--inline-color-gray10);
  }
}

.OutlinedBtn_default {
  border: 1px solid var(--color-gray13);
}

.OutlinedBtn_red {
  border: 1px solid var(--color-brand-toneup-red);
  color: var(--color-brand-toneup-red);
}

.OutlinedBtn_hide {
  border: 1px solid var(--color-gray1515);
  color: var(--color-gray10);
}

.OutlinedBtn_small {
  padding: unset;
  border-radius: 6px;
  font-size: 14px;
  line-height: 19.5px;
  border: 1px solid var(--color-gray13);
  color: var(--inline-color-gray10);
}

.OutlinedBtn_img {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    &.active {
      transform: rotate(180deg);
    }
  }
}
