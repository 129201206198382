input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

// iOS 자체 디자인 해제
input,
textarea,
button {
  appearance: none !important;
  // border-radius: 0;
  -moz-appearance: none !important;
  // -moz-border-radius: 0;
  -webkit-appearance: none !important;
  // -webkit-border-radius: 0;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}
@media (hover: hover) {
  a:hover {
    color: var(--inline-color-black);
    text-decoration: none;
    background-color: none;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: unset;
}

h2 {
  font-size: 18px;
  line-height: 27px;
}

h3 {
  // font-size: 16px;
  line-height: 20px;
}

h4 {
  font-size: 15px;
  line-height: 20px;
}

button,
input {
  letter-spacing: -0.04em;
  font-size: 14px;
  color: var(--inline-color-black);
}

input[type="search"]::-webkit-search-cancel-button {
  position: relative;
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 10px;
  background: var(--inline-color-gray10);
  background-image: url(../../../public/assets/icons/icon_close.svg);
}

input::placeholder,
textarea::placeholder {
  letter-spacing: 0;
}

input:focus,
select:focus,
option:focus {
  outline: none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
}

ul,
li {
  list-style: none;
}

label {
  display: flex;
  align-items: center;
}

input {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 21px;
  line-height: 20px;
  padding: 13px;
  border: 1px solid var(--inline-color-gray13);
  border-radius: 6px;
  background: var(--inline-color-white);
}

table {
  width: 100%;
  table-layout: fixed;
  border: 1px solid var(--inline-color-gray15);
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 6px;
  padding: 0;
  word-break: break-all;
  border-style: hidden;
  box-shadow: 0 0 0 1px var(--inline-color-gray15);
  overflow: hidden;
}

textarea {
  font-size: 14px;
  padding: 13px;
  width: 100%;
  border: 1px solid var(--inline-color-gray13);
  border-radius: 6px;
  background: var(--inline-color-white);
  resize: none;
}

#NewProduct,
#BestItem,
#PriceGolfHome {
  #RowChipList {
    padding-top: 0;
    margin: 0 -16px;
  }
}

b {
  font-weight: 400;
  font-family: var(--font-NotoSans_600);
}
