:root {
  --layout-padding-5: 5px;
  --layout-padding: 16px;
  --layout-padding-10: 10px;
  --layout-padding-24: 24px;
  --layout-margin: 16px;
  --layout-margin-10: 10px;
  --layout-margin-24: 24px;
  --layout-margin-gutter: 10px;
  --listitem-margin-gutter: 10px;

  --layout-header-padding: 10px;

  /* --layout-space: 16px;
  --layout-space-10: 10px; */

  /* --col_eight_width: calc(
    calc(100% - calc(7 * var(--listitem-margin-gutter))) / 8
  );
  --col_five_width: calc(
    calc(100% - calc(4 * var(--listitem-margin-gutter))) / 5
  );
  --col_four_width: calc(
    calc(100% - calc(3 * var(--listitem-margin-gutter))) / 4
  );
  --col_three_width: calc(
    calc(100% - calc(2 * var(--listitem-margin-gutter))) / 3
  );
  --col_two_width: calc(calc(100% - calc(var(--listitem-margin-gutter))) / 2);
  touch-action: pan-y; */
}
