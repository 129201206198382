:root {
  --z-index-action-buttons: 10;

  --z-index-gnb: 11;

  --z-index-bottom-navigation: 19;
  --z-index-sticky-panel: 20;

  --z-index-scroll-up-button: 499;

  --z-index-bottom-sheet: 502;
  --z-index-bottom-bar: 503;
  --z-index-overlay: 550;

  --z-index-main-container-border: 555;

  --z-index-modal: 600;

  --z-index-loading-page: 700;
}
