@use "/src/shared/styles/mixins/flexbox.scss";

.Switch {
  display: inline-block;

  input[type="checkbox"] {
    all: unset;
    overflow: hidden;
    position: relative;

    display: block;
    box-sizing: border-box;

    border-radius: 18px;
    border: 1px solid var(--color-gray-light);

    background-color: var(--color-gray-light);

    cursor: pointer;
    transition: all 150ms ease-in-out;

    &::before {
      content: "";
      position: absolute;

      border-radius: 50%;

      background-color: var(--color-white);

      transition: all 150ms ease-in-out;
      pointer-events: none;
    }
  }

  &:where(.size-m) {
    input[type="checkbox"] {
      width: 42px;
      height: 24px;

      &::before {
        top: 2px;
        left: 2px;

        width: 18px;
        height: 18px;
      }
    }
  }

  &:where(.size-l) {
    input[type="checkbox"] {
      width: 54px;
      height: 30px;

      &::before {
        top: 2px;
        left: 2px;

        width: 24px;
        height: 24px;
      }
    }
  }

  &.checked input[type="checkbox"]:checked,
  & input[type="checkbox"]:checked {
    border-color: var(--color-red);
    background-color: var(--color-red);

    &::before {
      transform: translateX(100%);
    }
  }

  &.disabled {
    opacity: 0.5;
    filter: grayscale(0.4);
  }
}
