@use "/src/shared/styles/mixins/flexbox.scss";

.ProductBrandSelectorModal {
  .header {
    box-shadow: 0 -5px 10px #000;

    .title {
      padding-bottom: 14px;
    }
  }

  .brandList {
    overflow-y: scroll;
    height: 65vh;
  }

  .brandItem {
    border-bottom: 1px solid var(--color-gray-light);
    padding: 14px 18px;

    button {
      width: 100%;
      text-align: start;
    }
  }

  .empty {
    @include flexbox.flexbox();
    height: 100%;
  }
}
