.Input {
  all: unset;

  box-sizing: border-box;

  width: 100%;

  border: 1px solid var(--color-gray-light);
  border-radius: 6px;

  background: var(--inline-color-white);

  &:where(.size-s) {
    padding: 5px;
  }

  &:where(.size-m) {
    padding: 13px;
  }

  font-size: 14px;
  line-height: 20px;

  &.fullWidth {
    width: 100%;
  }

  &.disabled,
  &:disabled {
    opacity: 0.7;
    background-color: var(--color-gray-lightest);
  }

  &:read-only {
    color: var(--color-gray-dark);
    background-color: var(--color-gray-lightest);
  }

  &.error {
    color: var(--color-error) !important;
    border-color: var(--color-error) !important;
    background-color: var(--color-error-background) !important;
  }
}
