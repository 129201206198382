#RefundAccountChange {
  margin-top: var(--size-gnb);
  padding: 16px;

  background-color: var(--color-white);

  h4 {
    margin-bottom: 16px;
  }
  #Select,
  input {
    margin-bottom: 10px;
    width: 100%;
  }
  input:disabled {
    background-color: var(--color-gray167);
  }
  .ContainedBtn {
    width: 100%;
  }
}
