@use "/src/shared/styles/mixins/position.scss";

.StickyPanel {
  @include position.fixed();
  top: 60px;
  left: 0;
  right: 0;
  z-index: var(--z-index-sticky-panel);

  margin-top: 0 !important;
  padding: 13px var(--layout-padding);
  border-bottom: 1px solid var(--inline-color-gray167);

  background: var(--color-white);
  transition: all 0.3s ease-in-out;

  .infoText,
  & button {
    text-align: center;
    font-family: var(--font-NotoSans_400);
  }

  .foldedChildren {
    overflow: hidden;

    padding-top: 3px;
    padding-bottom: 18px;
    transition: all 0.5s ease-in-out;
  }

  &:has(.infoText) .foldedChildren {
    padding-top: 22px;
  }

  .normalChildren {
    display: flex;
    justify-content: space-between;
  }

  //
  &.is-hidden {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 3px 10px;

    .foldedChildren {
      padding-bottom: 0;
      height: 0px !important;
    }
  }
}
