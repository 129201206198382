@use "/src/shared/styles/mixins/position.scss";

.BottomBar {
  @include position.fixed();
  z-index: var(--z-index-bottom-bar);
  left: 0;
  right: 0;
  bottom: 0;

  padding: 14px 16px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 8px);

  background-color: var(--color-white);

  &:where(.bordered) {
    border-top: 1px solid var(--color-gray-lightest);
  }

  &:where(.shadow) {
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
}
