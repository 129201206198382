.Image {
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: inherit;
    object-fit: cover;
  }

  &.ratio-1 {
    aspect-ratio: 1;
  }
}
