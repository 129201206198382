$border-color: var(--color-gray-light);

.Table {
  table-layout: fixed;
  overflow: hidden;

  width: 100%;

  padding: 0;

  border-collapse: collapse;
  border-radius: 6px;
  border-spacing: 0;
  border-style: hidden;
  box-shadow: 0 0 0 1px $border-color;

  text-align: center;
  word-break: break-all;
  font-size: 13px;
  line-height: 19.5px;

  .Th {
    border: 1px solid $border-color;
    padding: 5px;
  }

  .Td {
    border: 1px solid $border-color;
    padding: 10px 0;
  }
}
