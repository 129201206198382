@use "/src/shared/styles/mixins/flexbox.scss";
@use "/src/shared/styles/mixins/text.scss";

@use "../lib/ImageUploaderItem.scss";

.ItemPreview {
  @extend %image-uploader-item;
  // overflow: hidden;

  border: 1px solid var(--color-gray-light);

  .closeIcon {
    position: absolute;
    right: -6px;
    bottom: -6px;
  }

  &:where(.isLoading) {
    .loading {
      position: relative;
      width: 100%;
      height: 100%;
      aspect-ratio: 1;

      background-color: var(--color-gray-lightest);

      .image {
        filter: brightness(0.8);
        img {
          filter: blur(2px);
        }
      }

      .spinner {
        position: absolute;
        z-index: 1;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &:where(.state-error) {
    height: 100%;
    aspect-ratio: 1;

    color: var(--color-error);
    border-color: var(--color-error);
    background-color: var(--color-white);
    font-size: 14px;

    .error {
      @include flexbox.column-flexbox();
      gap: 2px;
      width: 100%;

      .textContainer {
        @include flexbox.flexbox();
        width: 100%;
        padding: 0 8px;

        span {
          @include text.truncate();
          width: 95%;
        }
      }
    }
  }
}
