//배송지 선택
#CheckoutSelectAddr {
  .wrapper {
    background-color: var(--color-gray167);
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 80px;
    padding: var(--layout-padding);
    button.add_addr {
      border: 1px solid var(--color-gray15);
      border-radius: 6px;
      background-color: var(--color-white);
      padding: 16px;
      width: 100%;
      font-size: 14px;
    }
  }
  #BottomBtnWrap {
    left: 0;
  }
}
//배송지 추가(내 배송지)
#CheckoutRegisterAddr,
#EditDeliveryAddr {
  overflow-x: scroll;
  height: 100vh;
  .error {
    color: var(--color-brand-toneup-red);
  }
  .wrapper {
    padding: var(--layout-padding);
  }
  .input_wrapper {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    input:first-of-type,
    select {
      margin-top: 10px;
    }
    input + input {
      margin-top: 10px;
    }
  }
  #CheckBox {
    margin-top: 20px;
  }
  .btn_wrapper {
    display: flex;
    width: 100%;
    margin-top: 25px;
    background-color: white;
    // border-top: 1px solid var(--color-gray13);

    // margin-top: 16px;
    // a + a {
    //   margin-left: 10px;
    // }
    button {
      // width: 100%;
      // &:first-of-type {
      //   margin-right: 10px;
      // }
      flex: 1;

      font-size: 16px;
    }
  }
}
