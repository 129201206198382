@use "/src/shared/styles/mixins/animation.scss";

.Spinner {
  display: inline-block;

  border-style: solid;
  border-top-color: transparent;
  border-right-color: var(--color-gray);
  border-bottom-color: var(--color-gray);
  border-left-color: var(--color-gray);
  border-radius: 50%;

  @include animation.spin();

  &:where(.size-xs) {
    width: 11px;
    height: 11px;
    border-width: 2px;
  }

  &:where(.size-s) {
    width: 18px;
    height: 18px;
    border-width: 2px;
  }

  &:where(.size-m) {
    width: 28px;
    height: 28px;
    border-width: 4px;
  }

  // colors
  &:where(.color-white) {
    border-right-color: white;
    border-bottom-color: white;
    border-left-color: white;
  }

  &:where(.color-red) {
    border-right-color: var(--color-red);
    border-bottom-color: var(--color-red);
    border-left-color: var(--color-red);
  }

  &:where(.color-blue) {
    border-right-color: var(--color-blue);
    border-bottom-color: var(--color-blue);
    border-left-color: var(--color-blue);
  }

  &:where(.color-error) {
    border-right-color: var(--color-error);
    border-bottom-color: var(--color-error);
    border-left-color: var(--color-error);
  }

  &:where(.color-black) {
    border-right-color: var(--color-black);
    border-bottom-color: var(--color-black);
    border-left-color: var(--color-black);
  }
}
