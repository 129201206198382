@use "/src/shared/styles/mixins/scrollbar.scss";
@import "/src/shared/styles/constants/Colors.css";
@import "/src/shared/styles/constants/Layouts.css";
@import "/src/shared/styles/constants/sizes.scss";
@import "/src/shared/styles/constants/zIndex.scss";

@font-face {
  font-family: "NotoSans_300";
  src: url("../shared/assets/fonts/Noto_Sans_KR/NotoSansKR-Light.otf");
}
@font-face {
  font-family: "NotoSans_400";
  src: url("../shared/assets/fonts/Noto_Sans_KR/NotoSansKR-Regular.otf");
}
@font-face {
  font-family: "NotoSans_500";
  src: url("../shared/assets/fonts/Noto_Sans_KR/NotoSansKR-Medium.otf");
}
@font-face {
  font-family: "NotoSans_600";
  src: url("../shared/assets/fonts/Noto_Sans_KR/NotoSansKR-Bold.otf");
}
@font-face {
  font-family: "NotoSans_700";
  src: url("../shared/assets/fonts/Noto_Sans_KR/NotoSansKR-Black.otf");
}
:root {
  --font-NotoSans_300: "NotoSans_300";
  --font-NotoSans_400: "NotoSans_400";
  --font-NotoSans_500: "NotoSans_500";
  --font-NotoSans_600: "NotoSans_600";
  --font-NotoSans_700: "NotoSans_700";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: "Noto Sans KR", sans-serif;
  -ms-overflow-style: none; /* IE, Edge */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* 버튼 음영 삭제 */
  -webkit-touch-callout: none; /* long touch 시 미리보기 off */

  @include scrollbar.no-scrollbar();
}

html {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  display: flex;
  height: 100vh;

  flex-direction: column;

  color: var(--inline-color-black);
  background-color: #f1f1f1;

  font-size: 14px;
  font-family: var(--font-NotoSans_400);
  letter-spacing: -0.04em;
  line-height: 21px;
  word-break: break-all;
  --webkit-backface-visibility: hidden;
}

#root {
  width: 100%;
  min-width: 300px;
  max-width: var(--size-max-width);
  min-height: 100%;

  margin: 0 auto;

  background-color: var(--color-white);

  &::before,
  &::after {
    content: "";
    position: fixed;
    top: 0;
    z-index: var(--z-index-main-container-border);

    display: block;
    width: 1px;
    height: 100%;

    background-color: #0000001a;
  }

  &::before {
    left: 50%;
    transform: translate(calc(-1 * var(--size-max-width) / 2));
  }

  &::after {
    right: 50%;
    transform: translate(calc(var(--size-max-width) / 2));
  }
}

textarea {
  font-family: var(--font-NotoSans_400) !important;
  word-break: keep-all;
}
