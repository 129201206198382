@use "/src/shared/styles/mixins/flexbox.scss";
@use "/src/shared/styles/mixins/position.scss";

.ImageZoomModal {
  @include flexbox.flexbox();
  position: relative;
  width: 100%;
  height: 100%;

  :global .pinch-zoom-container {
    width: 100% !important;
    height: 100% !important;
  }

  .pinchZoomContainer {
    width: 100%;
    height: 100%;
  }

  .closeButton {
    @include position.fixed-center-x();
    bottom: 7%;
  }
}
