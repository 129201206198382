@use "/src/shared/styles/mixins/flexbox.scss";

.Modal {
  position: relative;
  overflow-y: hidden;

  width: 80%;

  border-radius: 8px;

  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;

    font-size: 1.5rem;
    cursor: pointer;
  }
}

.Header,
.Body {
  padding: 14px;
}
