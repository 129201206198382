@use "/src/shared/styles/mixins/flexbox.scss";
@use "/src/shared/styles/mixins/text.scss";

// 이미지
.ProductImage {
  flex-shrink: 0;
  overflow: hidden;

  border-radius: 3px;

  &.size-s {
    width: 68px;
    height: 68px;
  }

  &.size-m {
    width: 80px;
    height: 80px;
  }

  &.size-l {
    width: 90px;
    height: 90px;
  }

  img {
    display: block;
    width: 100%;
  }
}

// 배송중 상태일 때 (배송조회)
.ProductDeliveryTracking {
  @include flexbox.flexbox(between);
  width: 100%;

  a {
    @include flexbox.flexbox();
    color: var(--color-brand-red);
  }
}

// 이름
.ProductName {
  .name {
    @include text.line-clamp(2);
  }
}

// 가격
.ProductPrice {
  text-align: end;
}

.TotalPrice {
  font-family: NotoSans_500;
  font-size: 16px;
}

// 상태
.Status {
  flex-shrink: 0;
  font-family: NotoSans_500;
  font-size: 15px;
}

// 회수 상태
.ProductPickStatus {
  padding: 3px 24px;
  border: 1px solid;
  border-radius: 4px;

  text-align: center;
}

// 연동판매
.ProductChannelInfo {
  .iconList {
    padding-top: 8px;
    overflow-x: scroll;

    .image {
      padding: 0 4px;
    }
  }
}
