#CheckBox {
  input[type="checkbox"]:checked {
    border: 1px solid var(--color-brand-toneup-red);
    background: var(--color-brand-toneup-red)
      url(../../../../../public/assets/icons/icon_check_white_nopadding.svg)
      no-repeat;
    background-size: 12px;
    background-position: 3px 4px;
    &.overseas:checked {
      border: 1px solid #006eff;
      background: #006eff
        url(../../../../../public/assets/icons/icon_check_white_nopadding.svg)
        no-repeat;
      background-size: 12px;
      background-position: 3px 4px;
    }
  }

  input[type="checkbox"] {
    appearance: initial;
    display: inline-block;
    margin-right: 6px;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid var(--inline-color-gray13);
    background-color: var(--inline-color-white);
    vertical-align: middle;
  }
  span {
    display: inline;
    padding-left: 6px;
    // white-space: nowrap;
  }
}
