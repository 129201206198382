@use "/src/shared/styles/mixins/flexbox.scss";
@use "/src/shared/styles/mixins/text.scss";

.HomeSearchLink {
  @include flexbox.flexbox(start);
  width: 100%;
  min-width: 120px;

  .title {
    @include flexbox.flexbox(start);
    flex: 1;

    width: 100%;
    min-width: 100px;
    height: 40px;

    border-bottom: 1px solid var(--color-gray-light);

    span {
      @include text.truncate();
      color: var(--color-text-gray-light);
      font-family: var(--font-NotoSans);
      font-size: 0.9rem;
      line-height: normal;
    }
  }

  .icon {
    @include flexbox.flexbox(start);
    flex-shrink: 0;
  }
}
