@use "/src/shared/styles/mixins/flexbox.scss";
@use "/src/shared/styles/mixins/position.scss";

.Header {
  @include position.fixed();
  top: 0;
  z-index: var(--z-index-gnb);

  @include flexbox.flexbox(start, start);

  padding: var(--layout-header-padding) 0;
  border-bottom: 1px solid var(--color-gray1515);

  font-size: 16px;
  line-height: 24px;
  background: var(--color-white);

  &:where(.size-m) {
    height: var(--size-gnb);
  }

  &:where(.size-l) {
    height: calc(var(--size-gnb) + var(--size-lnb));
  }
}
