/* legacy */
:root {
  --color-lemonyellow-bg: #fffef1;
  --color-icon-milege-point: #7fa790;
  --color-icon-premium-coupon: #a69dc1;
  --color-icon-starpoint: #ffcc00;
  --color-brand-red: #d51175;
  --color-brand-toneup-red: #ef2287;
  --color-brand-blue: #392cb8;
  --color-brand-tonedown-blue: #434954;
  --color-skyblue-bg: #f5f8fb;
  --color-skyblue-border: #ecf0ff;
  --color-black: #222222;
  --color-gray4: #51555c;
  --color-gray7: #666666;
  --color-gray10: #999999;
  --color-gray13: #c9ced6;
  --color-gray15: #e3e3e3;
  --color-gray1513: #ececec;
  --color-gray1515: #eeeeee;
  --color-gray167: #f6f6f6;
  --color-gray1610: #f9f9f9;
  --color-white: #fefefe;
  --color-gray-trans-imgbg: #dddddd33;
  --inline-color-black: #222222;
  --inline-color-gray4: #51555c;
  --inline-color-gray7: #666666;
  --inline-color-gray10: #999999;
  --inline-color-gray13: #c9ced6;
  --inline-color-gray15: #e3e3e3;
  --inline-color-gray1513: #ececec;
  --inline-color-gray1515: #eeeeee;
  --inline-color-gray167: #f6f6f6;
  --inline-color-gray1610: #f9f9f9;
  --inline-color-white: #fefefe;

  --color-text-gray: #777777;
  --color-text-gray-medium: #8e8c8c;
  --color-text-gray-light: #b1b0b0;
}

:root {
  --color-real-black: #000000;
  --color-black: #222222;
  --color-gray-darkest: #555555;
  --color-gray-darker: #777777;
  --color-gray-dark: #8e8c8c;
  --color-gray: #bbbbbb;
  --color-gray-light: #d8d8d8;
  --color-gray-lighter: #eeeeee;
  --color-gray-lightest: #f6f6f6;
  --color-white: #ffffff;

  --color-hover-black: #00000008;
  --color-active-black: #00000012;

  --color-red-darkest: #555555;
  --color-red-darker: #b90c64;
  --color-red-dark: #d51175;
  --color-red: #ef2287;
  --color-red-light: #ef2287;
  --color-red-lighter: #fbedf3;
  --color-red-lightest: #fff6fa;

  --color-blue-darkest: #22187d;
  --color-blue-darker: #2e229e;
  --color-blue-dark: #392cb8;
  --color-blue: #6155de;
  --color-blue-light: #8f87e5;
  --color-blue-lighter: #ece9ff;
  --color-blue-lightest: #f5f4ff;

  --color-background: var(--color-gray-lightest);
  --color-error: #fa5052;
  --color-error-dark: #e64244;
  --color-error-darker: #cd2628;
  --color-error-light: #ffdfdf91;
  --color-error-background: #ffebeb91;

  --color-naver: #01c73c;
}
