.PushNotifyBottomSheet {
  .buttonContainer {
    padding: 12px 0 24px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 8px);
  }

  .contentContainer {
    padding-bottom: 21px;

    .header {
      position: sticky;
      top: 0;

      padding-bottom: 12px;
      border-bottom: 1px solid var(--color-gray-lightest);

      background: var(--color-white);

      .title {
        font-size: 18px;
      }
    }
  }

  .content {
    white-space: pre;
    padding-top: 18px;
  }
}
