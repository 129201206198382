@use "../lib/ImageUploaderItem.scss";

.ItemInput {
  @extend %image-uploader-item;

  color: var(--color-gray);
  border: 1px dashed var(--color-gray);
  background-color: var(--color-gray-lightest);

  &.ratio-1 {
    aspect-ratio: 1;
  }

  .plusIcon {
    pointer-events: none;
  }

  &:not(.disabled):active {
    background-color: var(--color-gray-lighter);
  }
}
