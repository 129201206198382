.cart_icon {
  display: flex;
  position: relative;
  .count {
    position: absolute;
    right: 2px;
    top: 4px;
    background: var(--color-brand-toneup-red);
    width: 16px;
    height: 16px;
    border-radius: 16px;
    font-size: 10px;
    line-height: 16px;
    color: var(--color-white);
    text-align: center;
  }
}
