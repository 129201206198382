@use "/src/shared/styles/mixins/position.scss";

// #NoSellFakeProductModal {
.NoSellFakeProductModal {
  @include position.fixed();
  top: 50%;
  left: 50%;
  right: 50%;
  z-index: var(--z-index-modal);
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: var(--size-max-width-modal);
  max-height: 85%;
  visibility: hidden;

  padding: 0 16px 16px;
  border-radius: 6px;

  transform: translate(-50%, -50%);
  background-color: var(--color-white);

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .title {
    padding: 13px 0;
    margin-bottom: 20px;
    display: flex;

    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: var(--color-white);
    border-bottom: 1px solid rgb(170, 170, 170);
    button {
      padding: 5px 11px;
      font-size: 13px;
      line-height: 19.5px;
    }
  }

  ul {
    padding: 5px 0 20px;
    font-size: 13px;
  }
  .warning {
    color: red;
    font-size: 17px;
    text-align: center;
  }
  #CheckBox {
    display: flex;
    margin-top: 16px;
  }
  .ContainedBtn {
    margin-top: 18px;
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  .iconClose {
    width: 30px;
    height: 30px;
    border: 1px solid rgb(170, 170, 170);
    border-radius: 50%;
  }
}
