$flex-map: (
  start: flex-start,
  end: flex-end,
  between: space-between,
  around: space-around,
  stretch: stretch,
  center: center,
);

@function _get-flex-value($key) {
  @return map-get($flex-map, $key);
}

@mixin flexbox($jc: center, $ai: center) {
  display: flex;
  align-items: _get-flex-value($ai);
  justify-content: _get-flex-value($jc);
}

@mixin inline-flexbox($jc: center, $ai: center) {
  display: inline-flex;
  align-items: _get-flex-value($ai);
  justify-content: _get-flex-value($jc);
}

@mixin column-flexbox($jc: center, $ai: center) {
  display: flex;
  flex-direction: column;
  align-items: _get-flex-value($ai);
  justify-content: _get-flex-value($jc);
}
