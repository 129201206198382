@use "/src/shared/styles/mixins/position.scss";

#ModalPostCode {
  @include position.fixed();
  top: 0;
  height: 100vh;
  z-index: 21;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);

  button {
    position: absolute;
    width: 70px;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    padding: 9px 10px;
    font-size: 16px;
    border-radius: 6px;
    background-color: white;
    border: none;
  }
}
