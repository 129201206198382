@use "/src/shared/styles/mixins/flexbox.scss";

.SearchInput {
  position: relative;

  @include flexbox.flexbox(start);
  width: 100%;

  border: 1px solid var(--color-gray-light);
  border-radius: 6px;

  &.color-white {
    background: var(--color-white);
  }

  &.color-gray {
    background: var(--color-gray-lightest);
  }

  &.error {
    border-color: var(--color-error) !important;
  }

  input {
    width: 100%;

    border: none;
    padding: 12px;
    padding-bottom: 10px;

    background-color: transparent;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
}
