.Layout {
  padding-top: var(--size-gnb);
  padding-bottom: var(--size-bottom-navigation);

  &:where(.color-gray) {
    background-color: var(--color-gray-lightest);
  }

  .contents {
    min-height: calc(100vh - var(--size-gnb) - var(--size-bottom-navigation));

    padding: var(--layout-padding);
  }
}
