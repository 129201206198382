@mixin line-clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
