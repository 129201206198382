@mixin no-scrollbar() {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  & ::-webkit-scrollbar-track {
    display: none;
  }
}

@mixin show-scrollbar() {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
  }

  &::-webkit-scrollbar-track {
    display: block;
  }
}
