#PostInput {
  .address_wrapper {
    position: relative;
    input[name="postcode"] {
      & + img {
        width: 20px;
        position: absolute;
        right: 15px;
        top: 25px;
      }
    }
  }
  input:not([name="addressDetail"]) {
    background-color: #f6f6f6;
    color: gray;
  }
  input {
    width: 100%;
    margin-top: 10px;
  }
  .error {
    color: var(--color-brand-toneup-red);
  }
}
