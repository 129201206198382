@use "/src/shared/styles/mixins/flexbox.scss";
@use "/src/shared/styles/mixins/position.scss";
@use "/src/shared/styles/mixins/animation.scss";

.Overlay {
  @include flexbox.column-flexbox();

  inset: -1%;
  z-index: var(--z-index-overlay);

  &:where(.position-absolute) {
    position: absolute;
  }
  &:where(.position-fixed) {
    @include position.fixed();
  }

  &:where(.color-white) {
    background-color: var(--color-white);
  }
  &:where(.color-white-opacity) {
    background-color: rgba(255, 255, 255, 0.5);
  }
  &:where(.color-black) {
    background-color: var(--color-real-black);
  }
  &:where(.color-black-opacity) {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:where(.animation-fadeIn) {
    @include animation.fade-in();
  }

  &:where(.animation-fadeIn-slow) {
    @include animation.fade-in(350ms);
  }
}
