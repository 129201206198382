@use "/src/shared/styles/mixins/flexbox.scss";

$count-size: 16px;

.CartIcon {
  @include flexbox.flexbox();

  position: relative;

  .count {
    position: absolute;
    top: 4px;
    right: 2px;

    width: $count-size;
    height: $count-size;

    border-radius: 16px;

    background: var(--color-red);
    color: var(--color-white);

    font-size: 10px;
    line-height: $count-size;
    text-align: center;
  }
}
