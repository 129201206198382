@use "./mixins/adjust-sizes.scss";

.visuallyHidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.none-select {
  user-select: none;
  -webkit-user-select: none;
}

.fullWidthOffset {
  @include adjust-sizes.full-width-offset("noPadding");
}

.text12 {
  font-size: 12px;
  line-height: 18px;
}
.text13 {
  font-size: 13px;
  line-height: 19.5px;
}
.text14 {
  font-size: 14px;
  line-height: 21px;
}
.text15 {
  font-size: 15px;
  line-height: 22.5px;
}
.text16 {
  font-size: 16px;
  line-height: 24px;
}
.text19 {
  font-size: 19px;
  line-height: 30px;
}
.text20 {
  font-size: 20px;
  line-height: 30px;
}

.graytext {
  color: var(--color-text-gray);
}
.bluetext {
  color: var(--color-brand-blue);
}
.redtext {
  color: var(--color-brand-toneup-red);
}
.blacktext {
  color: var(--color-black);
}

.flex {
  display: flex;
}

.left_auto {
  margin-left: auto;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.margin-top-10 {
  margin-top: var(--layout-margin-10);
}

.margin-top-16 {
  margin-top: var(--layout-margin);
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-10 {
  margin-bottom: var(--layout-margin-10);
}

.margin-bottom-16 {
  margin-bottom: var(--layout-margin);
}
.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-left-3 {
  margin-left: 3px;
}

.padding-5 {
  padding: var(--layout-padding-5);
}

.bold {
  font-weight: 600;
}
// 가로선
.divider {
  margin: var(--layout-margin) 0;
  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--color-gray1515);
  }
}

// 텍스트 말줄임
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4em;
  // height: 100%;
}

// 가로 스크롤 바
.row_scroll_menu {
  display: flex;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  background-color: var(--color-white);
  padding: 0 var(--layout-padding);
  margin: 0 -16px;
}

// 보더 컨테이너
.border-container,
.border-container-24 {
  background: var(--color-white);
  padding: var(--layout-padding);
  padding-top: var(--layout-padding);
  border-bottom: var(--layout-margin-gutter) solid var(--color-gray167);

  .title {
    display: flex;
    justify-content: space-between;
  }
  & > h3:not(#PriceGolfHome) {
    padding-bottom: var(--layout-padding);
  }
}

.pre-wrap {
  white-space: pre-wrap;
}
h3,
.item_title,
.sale_price,
.discount_price {
  font-family: var(--font-NotoSans_600);
}

.price-change {
  font-size: 14px;
}
.price_change_wrap {
  display: flex;
  justify-content: flex-end;
}
.font_300 {
  font-family: var(--font-NotoSans_300);
}
.font_400 {
  font-family: var(--font-NotoSans_400);
}
.font_500 {
  font-family: var(--font-NotoSans_500);
}
.font_600 {
  font-family: var(--font-NotoSans_600);
}
#FilterWrap {
  display: flex;
  align-items: center;
  background: var(--color-white);
  // padding: 12px 16px;

  > * {
    display: flex;
  }

  button {
    background: none;
    display: inline-flex;
    align-content: center;
    padding: 5px 5px 5px 0px;
  }

  .filter-btn {
    border: none;
  }
}

.product_wrap {
  padding: 0 var(--layout-padding) var(--layout-padding);
}

.grid_view {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  // padding: var(--layout-padding) 0;
}

.grid_style {
  padding: 0 var(--layout-padding) var(--layout-padding);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  background-color: var(--color-white);
}
.column_style {
  padding: var(--layout-padding);
  background-color: var(--color-white);
}

#RealTimeProduct,
#LowPriceProduct,
#UsedPrice,
#PowerZone,
#Category {
  background-color: var(--color-white);
}

#PriceGolfHome,
#NewProductShop {
  button {
    width: 100%;
  }
}

.post_content {
  img {
    height: 100%;
    max-width: 100%;
  }
}

.border-bottom {
  border-bottom: 1px solid var(--inline-color-gray15);
}

.table_border {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 6px;
  padding: 0;
  word-break: break-all;
  border-style: hidden;
  box-shadow: 0 0 0 1px var(--inline-color-gray15);
  overflow: hidden;
  text-align: center;
  td {
    padding: 10px 0;
    border: 1px solid var(--inline-color-gray15);
  }
}

.contents {
  padding: var(--layout-padding);
}

.rounded {
  border-radius: 6px;
  border: 1px solid var(--color-gray15);
}

.select_group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  #Select {
    width: 50%;
  }
}

.search_input {
  display: flex;
  img {
    display: flex;
    width: 40px;
    position: absolute;
    padding-top: 10px;
    right: 20px;
  }
}

input[readOnly] {
  background: var(--inline-color-gray167);
  color: var(--inline-color-gray10);
}

.price {
  font-size: 15px;
  letter-spacing: -0.06em;
}

.won {
  margin-left: 2px;
}

.title_little {
  display: flex;
  padding: 8px 0px;
  min-height: 20px;
  margin-bottom: 0px;
}

.text_center {
  text-align: center;
}

.product_status_table {
  li {
    display: flex;
    word-break: break-all;
    > *:first-child {
      flex: 0 0 100px;
    }
    &:not(:last-of-type) {
      margin-bottom: var(--layout-margin-gutter);
    }
  }
}

.btn_wrap {
  display: flex;
  gap: 3px;
  button {
    padding: 5px 11px;
    color: unset;
    font-size: 13px;
    line-height: 19.5px;
  }
}

.badge_wrap {
  margin-bottom: 5px;
  white-space: nowrap;
  span {
    display: inline-block;
    border: 1px solid black;
    padding: 0 4px;
    border-radius: 3px;
    font-size: 10.5px;
    & + span {
      margin-left: 4px;
    }
  }
  .badge_saleType {
    color: #d51175;
    border-color: #d51175;
  }
  .badge_shippingType {
    color: #00bcd4;
    border-color: #00bcd4;
  }
  .badge_isAd {
    color: #a69dc1;
    border-color: #a69dc1;
  }
  .badge_newProduct {
    color: var(--color-brand-blue);
    border-color: var(--color-brand-blue);
  }
}

.validationErrorMessage {
  color: var(--color-brand-toneup-red);
  &::before {
    display: inline;
    content: "\26A0";
    margin-right: 3px;
  }
}

.icon_close {
  background-color: white;
}
.swal2-html-container {
  white-space: pre-wrap;
}

.swal2-actions .swal2-confirm {
  background-color: #ef2287 !important;
  color: white !important;
  border: none !important;
  box-shadow: none !important;
  transition-delay: 0.5s !important;
}
.preventZoom {
  touch-action: pan-x pan-y;
}

//react-hook-form이 적용된 폼 기본 양식
.default_form_style {
  padding: 16px 16px 30px;
  li {
    margin-top: 16px;
  }
  input {
    width: 100%;
  }
  #Select,
  #CheckBox,
  input {
    margin-top: 10px;
  }
  span {
    display: block;
  }
  .error {
    color: var(--color-brand-toneup-red);
    margin-top: 4px;
  }

  .ContainedBtn {
    width: 100%;
    margin-top: 16px;
  }
}
.line_through {
  text-decoration: line-through;
}

//Quill cusotm css
.ql-snow .ql-picker.ql-font {
  width: 95px !important;
}
.ql-snow .ql-picker.ql-header {
  width: 91px !important;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 10px !important;
  padding: 4px 0;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 300px !important;
}

.ql-editor {
  min-height: 300px !important;
}
.ql-align-center {
  text-align: center;
}
.overseas_title {
  display: flex;
  justify-content: space-between;
}

.styledSelect {
  position: relative;
  box-sizing: border-box;

  display: block;

  width: 100%;

  padding: 13px;
  padding-right: 32px;
  border: 1px solid var(--color-gray-light);
  border-radius: 6px;

  font-size: 14px;
  line-height: 20px;
  text-align: start;

  color: #000;
  background: url(../../../public/assets/icons/icon_blackarrow_down.svg)
    no-repeat right 0.75rem center;

  appearance: none;
}
