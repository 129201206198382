@use "/src/shared/styles/mixins/flexbox.scss";

%image-uploader-item {
  @include flexbox.flexbox();
  flex-shrink: 0;

  position: relative;

  color: var(--color-gray-darker);

  &,
  img {
    border-radius: 6px;
  }
}
