@use "/src/shared/styles/mixins/flexbox.scss";

.Button {
  @include flexbox.flexbox();

  position: relative;
  overflow: hidden;

  letter-spacing: -0.04em;

  &.isLoading .text {
    color: transparent;
  }

  /* Size */
  &:where(.size-xs) {
    padding: 5px 10px;

    font-size: 13px;
    line-height: 19px;
  }

  &:where(.size-s) {
    padding: 7px 11px;

    font-size: 14px;
    line-height: 19px;
  }

  &:where(.size-m) {
    padding: 10px 14px 8px;

    font-size: 14px;
    line-height: 19.5px;
  }

  &:where(.size-l) {
    padding: 13px 24px 12px;

    font-size: 16px;
    line-height: 21px;
  }

  &:where(.fullWidth) {
    width: 100%;
  }

  /* Color variants */
  &:where(.style-none) {
    border-radius: 6px;
    border: 1px solid transparent;

    &:where(.color-gray) {
      color: var(--color-gray-dark);

      &:not(:disabled):active {
        background-color: var(--color-gray-lightest);
      }
    }

    &:where(.color-black) {
      color: var(--color-black);

      &:not(:disabled):active {
        background-color: var(--color-gray-lightest);
      }
    }

    &:where(.color-red) {
      color: var(--color-red);

      &:not(:disabled):active {
        background-color: var(--color-red-lighter);
      }
    }

    &:where(.color-red-gray) {
      color: var(--color-gray-dark);

      &:not(:disabled):active {
        background-color: var(--color-red-lighter);
      }
    }

    &:where(.color-blue) {
      color: var(--color-blue);

      &:not(:disabled):active {
        background-color: var(--color-blue-lighter);
      }
    }

    &:where(.color-error) {
      color: var(--color-error);

      &:not(:disabled):active {
        background-color: var(--color-error-light);
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &:where(.style-outlined) {
    border-radius: 6px;
    border: 1px solid transparent;

    background-color: var(--color-white);
    white-space: nowrap;

    &:where(.color-gray) {
      border-color: var(--color-gray);
      color: var(--color-gray-dark);

      &:not(:disabled):active {
        background-color: var(--color-gray-lighter);
      }
    }

    &:where(.color-black) {
      border-color: var(--color-black);
      color: var(--color-black);

      &:not(:disabled):active {
        background-color: var(--color-gray-lighter);
      }
    }

    &:where(.color-red-gray) {
      border-color: var(--color-red-dark);
      color: var(--color-gray-dark);

      &:not(:disabled):active {
        background-color: var(--color-red-lighter);
      }
    }

    &:where(.color-red) {
      border-color: var(--color-red);
      color: var(--color-red);

      &:not(:disabled):active {
        background-color: var(--color-red-lighter);
      }
    }

    &:where(.color-blue) {
      border-color: var(--color-blue);
      color: var(--color-blue);

      &:not(:disabled):active {
        background-color: var(--color-blue-lighter);
      }
    }

    &:where(.color-error) {
      border-color: var(--color-error);
      color: var(--color-error);

      &:not(:disabled):active {
        background-color: var(--color-error-light);
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &:where(.style-solid) {
    border-radius: 6px;
    border: 1px solid transparent;

    color: white;
    white-space: nowrap;

    &:where(.color-gray) {
      border-color: var(--color-gray);
      background-color: var(--color-gray-dark);

      &:not(:disabled):active {
        background-color: var(--color-gray-darkest);
      }
    }

    &:where(.color-black) {
      border-color: var(--color-gray-darkest);
      background-color: var(--color-gray-darkest);

      &:not(:disabled):active {
        background-color: var(--color-black);
      }
    }

    &:where(.color-red) {
      border-color: var(--color-red);
      background-color: var(--color-red);

      &:not(:disabled):active {
        border-color: var(--color-red-darker);
        background-color: var(--color-red-darker);
      }
    }

    &:where(.color-red-gray) {
      border-color: var(--color-red);
      background-color: var(--color-red);

      &:not(:disabled):active {
        border-color: var(--color-red-darker);
        background-color: var(--color-red-darker);
      }
    }

    &:where(.color-blue) {
      border-color: var(--color-blue);
      background-color: var(--color-blue);

      &:not(:disabled):active {
        border-color: var(--color-blue-darkest);
        background-color: var(--color-blue-darkest);
      }
    }

    &:where(.color-error) {
      border-color: var(--color-error);
      background-color: var(--color-error);

      &:not(:disabled):active {
        border-color: var(--color-error-darker);
        background-color: var(--color-error-darker);
      }
    }

    &:disabled {
      background-color: var(--color-gray-light);
      border-color: var(--color-gray-light);
    }
  }
}
