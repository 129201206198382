@use "/src/shared/styles/mixins/flexbox.scss";
@use "/src/shared/styles/mixins/position.scss";

$side-width: 80px;

.LocalHeader {
  @include position.fixed();
  top: 0;
  left: 0;
  right: 0;

  @include flexbox.flexbox(between);
  padding: 0 var(--layout-header-padding);

  .left {
    flex-shrink: 0;

    .back,
    .logo {
      display: inline-block;
      width: 40px;
    }

    .logo {
      color: transparent;
    }
  }

  .center {
    .title {
      font-family: var(--font-NotoSans_500);
    }
  }

  .right {
    min-width: $side-width;
  }
}
