@use "/src/shared/styles/mixins/adjust-sizes.scss";

$border-color: var(--color-gray-light);
$padding: var(--layout-padding);

.Section {
  background-color: var(--color-white);

  padding: $padding;

  &:where(.fullWidthOffset) {
    @include adjust-sizes.full-width-offset();
  }

  &:where(.type-card) {
    border: 1px solid $border-color;
    border-radius: 6px;

    .title {
      font-family: var(--font-NotoSans_600);
      font-size: 15px;
      line-height: 20px;
    }

    &:not(.AccordionSection) .title {
      padding-bottom: $padding;
    }

    &:not(.AccordionSection) .container,
    &.AccordionSection .content {
      position: relative;
      left: calc(-1 * $padding);

      width: calc(100% + $padding * 2);

      padding: $padding;
      border-top: 1px solid $border-color;
    }

    &:not(.AccordionSection) .container {
      padding-bottom: 0;
    }
    padding: var(--layout-padding);
  }
}
