@use "/src/shared/styles/mixins/position.scss";

#VatContainer {
  @include position.fixed();
  margin-top: var(--size-gnb);

  top: 0;
  bottom: 0;
  height: 100%;
  overflow-y: scroll;
  z-index: 21;
  background-color: white;
  .btnWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px;
    button {
      border-radius: 6px;
      padding: 10px;
      white-space: nowrap;
      background: white;
      color: var(--inline-color-gray10);
      border: 1px solid var(--color-gray13);
      &:active {
        background: #f0f0f0;
      }
    }
  }
}

#IssueTaxInvoice {
  header {
    position: sticky;
  }

  .table_wrapper {
    border-radius: 0;
    background-color: var(--color-white);
    table {
      width: 100%;
      font-size: 12px;
      letter-spacing: -1px;
      border: 1px solid #a3d3ff;
      margin-bottom: 10px;
      border-collapse: collapse;
      tr,
      td {
        border-collapse: collapse;
        border: 1px solid #a3d3ff;
        padding-left: 10px;
      }
      .title {
        padding: 5px;
        background-color: #d9edff;
        // white-space: nowrap;
      }
      .w30 {
        width: 30px;
      }
      .w25 {
        width: 25px;
      }
      .w40 {
        width: 40px;
      }
      .w70 {
        width: 70px;
      }
      .w80 {
        width: 80px;
      }
      .ellipsis {
        border: unset;
        padding: unset;
      }
      .date {
        padding: unset;
      }
    }
  }
}
