.PushNotifyMetaData {
  .thumb {
    flex-shrink: 0;
    overflow: hidden;

    width: 26px;
    height: 26px;

    border-radius: 50%;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .icon {
      position: relative;
      top: 1px;
      left: 2px;
    }
  }

  .category {
    font-size: 14px;
    opacity: 0.85;
  }

  .date {
    color: var(--color-gray-dark);
    font-size: 13px;
  }
}
