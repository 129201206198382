@use "/src/shared/styles/mixins/flexbox.scss";

.AccordionItem {
  .trigger {
    @include flexbox.flexbox(start);
    width: 100%;
    word-break: keep-all;

    .title {
      flex: 1;
      text-align: start;
    }

    .arrowIcon {
      transition: transform 0.1s;
    }
  }

  .panel {
    padding-top: 6px;
  }

  &.isOpen {
    .trigger {
      .arrowIcon {
        transform: rotate(-180deg);
      }
    }
  }
}
