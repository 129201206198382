.Tabs {
  background: var(--color-white);

  &:where(.sticky) {
    position: sticky;
    top: var(--size-gnb);
    z-index: 1;
  }

  // styles
  &:where(.style-underlined) {
    &:where(.bordered) {
      .tabList {
        border-bottom: 1px solid var(--color-gray-light);
      }
    }

    .tabItem {
      width: 100%;
      height: 56px;

      padding-top: 5px;

      border-bottom: 3px solid transparent;

      color: var(--color-black);
      background-color: var(--color-white);
      opacity: 0.4;

      font-size: 15px;
      font-family: var(--font-NotoSans_600);

      &.isActive {
        opacity: 1;
        border-bottom-color: var(--color-red);
      }
    }
  }

  &:where(.style-pill) {
    .tabList {
      gap: 0 !important;
      overflow: hidden;

      border-radius: 5px;
    }

    .tabItem {
      width: 100%;

      padding: 10px 20px;

      background-color: var(--color-gray-lightest);
      color: var(--color-gray-darker);

      &.isActive {
        background-color: var(--color-black);
        color: var(--color-white);
      }
    }
  }
}
