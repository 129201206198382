@use "/src/shared/styles/mixins/adjust-sizes.scss";

.Divider {
  width: 100%;

  &:where(.fullWidthOffset) {
    @include adjust-sizes.full-width-offset();
  }

  &:where(.size-xxs) {
    height: 1px;
  }
  &:where(.size-xs) {
    height: 4px;
  }
  &:where(.size-s) {
    height: 6px;
  }
  &:where(.size-m) {
    height: 12px;
  }
}
