@use "/src/shared/styles/mixins/flexbox.scss";

.Badge {
  @include flexbox.inline-flexbox(center, center);

  border: 1px solid transparent;
  border-radius: 30px;

  // size
  &:where(.size-s) {
    padding: 0px 8px;
    font-size: 12px;
  }

  &:where(.size-m) {
    padding: 5px 14px;
  }

  &:where(.size-l) {
    padding: 8px 16px;
  }

  // colors
  &:where(.color-white) {
    background-color: var(--color-white);
    border-color: var(--color-gray-light);
    color: var(--color-gray-darker);
  }

  &:where(.color-gray) {
    background-color: var(--color-gray-lightest);
    border-color: #f3f3f3;
    color: var(--color-black);
  }

  &:where(.color-red) {
    background-color: var(--color-red);
    border-color: var(--color-red);
    color: var(--color-white);
  }

  &:where(.color-red-light) {
    background-color: var(--color-red-lighter);
    border-color: var(--color-red);
    color: var(--color-red);
  }
}
