.Select {
  position: relative;

  &,
  select {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font-NotoSans_400);
  }

  select {
    appearance: none;

    width: 100%;

    padding: 13px;
    padding-right: 32px;
    border: 1px solid var(--color-gray-light);
    border-radius: 6px;

    color: var(--color-black);
    background-color: inherit;
  }

  &.disabled,
  & select:disabled {
    opacity: 0.85;
    background-color: var(--color-gray-lightest);
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 12px;

    transform: translateY(-50%);
  }

  &.error {
    select {
      border-color: var(--color-error);
      background-color: var(--color-error-background);
    }
  }
}
