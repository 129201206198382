#FileUpLoad,
#ThumbnailImageUpload {
  img {
    width: 20px;
  }
  .submit_file_btn {
    display: none;
  }
  .preview_wrapper {
    width: 120px;
    height: 120px;
    overflow-x: scroll;
    border-radius: 3px;
    border: 1px solid black;
    border: 1px solid var(--inline-color-gray13);
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .plus_icon {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }

  input.hide {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  .imageFile_wrapper {
    position: relative;

    .icon_close {
      width: 25px;
      height: 25px;
      padding: 0px;
      border-radius: 50%;
      box-sizing: content-box;
      background-color: white;
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 10;
    }

    img {
      width: 120px;
      height: 120px;
      border-radius: 3px;
      margin-right: 10px;
      border: 1px solid var(--inline-color-gray13);
    }
  }
}
