@function _padding($padding, $hasPadding) {
  @if $hasPadding == "padding" {
    @return $padding;
  }
  @return 0;
}

@mixin full-width-offset(
  $hasPadding: "padding",
  $padding: var(--layout-padding)
) {
  width: calc(100% + ($padding * 2));
  transform: translateX(calc(-1 * $padding));

  @if $hasPadding == "padding" {
    padding-left: _padding($padding, $hasPadding);
    padding-right: _padding($padding, $hasPadding);
  }
}
