.Message {
  border-radius: 4px;
  border: 1px solid;
  word-break: keep-all;

  &:where(.size-s) {
    padding: 2px 0.75rem;
  }

  &:where(.size-m) {
    padding: 10px 0.75rem;
  }

  &:where(.size-l) {
    padding: 21px 14px;
  }

  text-align: center;

  &:where(.color-red) {
    border-color: #fdd2d9;
    background-color: #fee7ed;
    color: #ef1e83;
  }

  &:where(.color-blue) {
    background-color: #e7f1fe;
    border-color: #d2e5fd;
    color: #3e88e8;
  }

  &:where(.color-error) {
    background-color: var(--color-error-background);
    border-color: #ffcccd;
    color: var(--color-error);
  }

  &:where(.color-none) {
    border-color: var(--color-gray-light);
    color: var(--color-gray-darkest);
  }
}
