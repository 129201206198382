.ItemListLayout {
  padding-top: var(--size-gnb);
  padding-bottom: var(--size-bottom-navigation);

  &:where(.color-white) {
    background-color: var(--color-white);
  }

  .itemList {
    padding: var(--layout-padding);
  }

  .emptyView {
    padding-top: 15vh;

    font-size: 15px;
    text-align: center;
  }
}
