@use "/src/shared/styles/mixins/position.scss";

#PopUpView {
  @include position.fixed();
  top: 0;
  bottom: 0;
  z-index: 200;

  padding: var(--layout-pading);
  padding-top: var(--size-gnb);

  touch-action: pan-x pan-y;

  #icon_close {
    position: absolute;
    top: 10px;
    left: 5px;
    z-index: 11;
  }
  .wrapper {
    min-height: calc(100dvh - var(--size-gnb) - var(--size-bottom-navigation));
    height: 100%;
    overflow-y: scroll;
  }
  .no_title {
    margin-top: 0;
    background-color: white;
    overflow-y: scroll;
    height: 100%;
  }
}
