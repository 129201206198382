@use "/src/shared/styles/mixins/text.scss";

$badgeSize: 6px;

.PushNotifyCard {
  position: relative;

  background-color: var(--color-white);
  box-shadow: rgba(99, 99, 99, 0.13) 0px 2px 8px 0px;

  // &:where(:not(.isRead):not(.skeleton))::after {
  //   content: "";

  //   position: absolute;
  //   top: 12px;
  //   right: 12px;

  //   width: $badgeSize;
  //   height: $badgeSize;

  //   border-radius: 16px;

  //   background-color: var(--color-red);
  // }

  &:where(.isRead) {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

    > * {
      opacity: 0.5;
    }
  }

  .card-header {
    padding-bottom: 0;
  }

  .card-body {
    padding-top: 8px;
    padding-bottom: 16px;

    .content {
      padding: 0 4px;

      .title {
        @include text.truncate();
        font-size: 15px;
        margin-bottom: 2px;

        .canceledTitle {
          color: #6563ff;
        }
      }

      .text {
        @include text.line-clamp(2);
      }
    }
  }
}
