@use "/src/shared/styles/mixins/flexbox.scss";

.ImageUploader {
  @include flexbox.flexbox(start);

  .list {
    @include flexbox.flexbox(start);

    overflow-x: scroll;

    margin-right: 4px;
  }

  .item {
    user-select: none;

    width: 125px;
    margin: 0 4px;
  }
}
