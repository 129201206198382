@use "/src/shared/styles/mixins/flexbox.scss";

$red: var(--color-brand-toneup-red);

.Radio {
  width: 100%;

  .text {
    margin-left: 6px;
  }

  .label {
    @include flexbox.inline-flexbox(start);

    min-height: 20px;

    padding: 8px;
    padding-left: 0;

    cursor: pointer;

    input[type="radio"] {
      all: unset;
      position: relative;

      display: inline-block;
      width: 20px;
      height: 20px;

      margin: 0;
      padding: 0;
      border: 1px solid var(--inline-color-gray13);
      border-radius: 20px;

      background: var(--inline-color-white);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(50%, 50%);

        display: block;
        width: 50%;
        height: 50%;

        border-radius: 50%;

        background-color: transparent;
      }
    }

    &:not(.disabled).checked input[type="radio"],
    & input[type="radio"]:not(:disabled):checked {
      border-color: $red;
      &::before {
        background: $red;
      }
    }

    &.disabled {
      opacity: 0.9;
      cursor: not-allowed;
      color: var(--color-gray);

      input[type="radio"]:disabled {
        background: var(--color-gray-lighter);
      }

      &.checked input[type="radio"] {
        &::before {
          background: var(--color-gray-dark);
        }
      }
    }
  }
}

.RadioGroup {
  .Radio .label {
    display: flex;

    padding: 14px 0;
    border-bottom: 1px solid #eeeeee;

    font-size: 16px;
    line-height: 24px;
  }
}
