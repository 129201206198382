@use "/src/shared/styles/mixins/position.scss";
@use "/src/shared/styles/mixins/animation.scss";

.BottomSheet {
  @include position.fixed();

  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-bottom-sheet);
  overflow: hidden;

  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);

  &:where(.modal-open) {
    @include animation.scroll-up();
    display: block;
    transform: translateY(0px);
  }

  &:where(.modal-closing) {
    transform: translateY(100%) !important;
    transition: transform 250ms ease-in-out;
  }

  &:where(.modal-closed) {
    display: none;
  }

  .sheetHeader {
    padding: 16px;
    padding-bottom: 6px;

    color: var(--color-gray-lighter);
  }

  .sheetContent {
    padding: 16px;

    &.hasNavigation {
      padding-bottom: calc(var(--size-bottom-navigation) + 16px);
    }
  }
}
