.Container {
  padding-bottom: 16px;

  &:where(.size-s) {
    .Title {
      font-size: 16px;
      font-family: var(--font-NotoSans_600);
    }
  }

  &:where(.size-m) {
    .Title {
      font-size: 18px;
      font-family: var(--font-NotoSans_600);
    }
  }

  &:where(.size-l) {
    .Title {
      font-size: 21px;
      font-family: var(--font-NotoSans_600);
    }
  }

  &.noPadding {
    padding-bottom: 0;
  }

  &.hasBottomBorder {
    margin-bottom: 4px;
    border-bottom: 1px solid var(--color-gray-light);
  }

  .count {
    flex-shrink: 0;
    font-size: 16px;
    font-family: var(--font-NotoSans_600);

    color: var(--color-red);
  }

  .link {
    flex-shrink: 0;
  }

  .moreLink {
    color: var(--color-gray);
    font-family: var(--font-NotoSans_400);

    .moreLinkText {
      font-size: 14px;
    }
  }
}
