@use "/src/shared/styles/mixins/flexbox.scss";

.Uploader {
  @include flexbox.inline-flexbox();
  flex-shrink: 0;

  overflow: hidden;

  width: 100%;
  height: 100%;

  color: inherit;
  transition: opacity 250ms ease-in-out;

  .inner {
    position: relative;

    .defaultButton {
      .text {
        line-height: 1.3;
      }
    }

    .fileInput {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    .loading {
      .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
