@use "/src/shared/styles/mixins/flexbox.scss";

$size-s: 20px;
$size-m: 26px;

.Checkbox {
  > label {
    @include flexbox.inline-flexbox(start);
    gap: 6px;

    line-height: 1;

    cursor: pointer;
  }

  .inputWrapper {
    @include flexbox.inline-flexbox(start);
    position: relative;
  }

  input[type="checkbox"] {
    all: unset;
    box-sizing: border-box;

    border-radius: 4px;
    border: 1px solid var(--inline-color-gray13);
    background: var(--inline-color-white);

    & + .checkIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      color: var(--color-gray15);
      opacity: 0.5;
    }

    //   size
    &.size-s {
      width: $size-s;
      height: $size-s;

      & + .checkIcon {
        width: 12px;
        height: 12px;
      }
    }

    &.size-m {
      width: $size-m;
      height: $size-m;

      & + .checkIcon {
        width: 14px;
        height: 14px;
      }
    }
  }

  &.checked input[type="checkbox"]:not(:disabled),
  input[type="checkbox"]:not(:disabled):checked {
    border: 1px solid var(--color-brand-toneup-red);
    background: var(--color-brand-toneup-red);

    & + .checkIcon {
      color: white;
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.5;

    input[type="checkbox"]:disabled:checked {
      background: var(--color-gray);
    }
  }
}
