@use "/src/shared/styles/mixins/flexbox.scss";

.Editor {
  position: relative;

  &.isLoading {
    opacity: 0.5;
    cursor: progress;
  }

  .loading {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
