$paddingTop: calc(var(--size-gnb) + var(--size-lnb));

.HomeLayout {
  padding-top: $paddingTop;
  padding-bottom: var(--size-bottom-navigation);

  .contents {
    min-height: calc(100vh - $paddingTop);
    background-color: var(--color-gray-lighter);
  }
}
