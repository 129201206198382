#Select {
  border-radius: 6px;
  background-color: white;

  select {
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 21px;
    line-height: 20px;
    padding: 13px;
    padding-right: 32px;
    border: 1px solid var(--inline-color-gray13);
    border-radius: 6px;
    background: url(../../../../public/assets/icons/icon_blackarrow_down.svg)
      no-repeat right 0.75rem center;
    color: #000;
    appearance: none;
  }
}
